import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import antdPtBr from 'antd/lib/locale-provider/pt_BR';
import themes from '@iso/config/theme/theme.config';

export default function AppProvider({ children }) {
  // const { locale } = useSelector(state => state.LanguageSwitcher.language);
  // const currentAppLocale = AppLocale[locale];
  return (
    <ConfigProvider locale={antdPtBr}>
      <IntlProvider locale={'pt_BR'}>
        <ThemeProvider theme={themes.defaultTheme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
